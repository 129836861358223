import React, { useRef } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import AboutContact from "../../components/Contact/AboutContact";

const Partner = () => {
  const data = [
    {
      id: 1,
      title: "Researchers",
      desc: "We’re seeking AI Researchers driven by discovery and innovation, aligning with our mission to create human-centric AI-driven solutions.",
    },
    {
      id: 1,
      title: "System Integrators",
      desc: "We collaborate with system integrators, merging our inventive solutions with their technical prowess to forge integrated systems, propelling mutual growth and triumph.",
    },
    {
      id: 1,
      title: "Consultants",
      desc: "We partner with consultants to co-create solutions, leveraging their industry insights to enhance our offerings and drive mutual growth and success.",
    },
    {
      id: 1,
      title: "Affiliates",
      desc: "We’re on the lookout for affiliate partners ready to join forces with us, propelling our AI-driven solutions to a wider audience.",
    },
    {
      id: 1,
      title: "OEM / Resellers",
      desc: "Our partnership model is designed for symbiotic success, offering dedicated support and opportunities for growth.",
    },
    {
      id: 1,
      title: "VCs / Investors",
      desc: "For forward-thinking VCs & investors interested in forging strategic partnerships that transcend traditional investment, this partnership is about creating synergies to achieve mutual growth.",
    },
  ];
  const contactFormRef = useRef(null);
  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Navbar />
      <div className="partner-wrap">
        <div className="partner-page">
          <h1>Partner with US</h1>
          <p>
            At Hushl, we’re always looking to collaborate with like-minded
            folks. If you’re passionate about innovation and eager to make a
            difference with AI-driven solutions, let’s join forces and create
            something extraordinary together.
          </p>
          <div className="nabar-cta partner-cta">
            <button
              onClick={() => {
                scrollToContactForm();
              }}
            >
              Become a partner
            </button>
          </div>
        </div>
        <div className="partner-container">
          {/* <h1>Partner with US</h1> */}
          <div className="partner-container-wrap">
            {data.map((item) => {
              return (
                <div className="partner-card">
                  <h3>{item.title}</h3>
                  <p>{item.desc}</p>
                  <div className="nabar-cta partner-card-cta">
                    <button
                      onClick={() => {
                        scrollToContactForm();
                      }}
                    >
                      Get Started
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div ref={contactFormRef}>
        <AboutContact />
      </div>

      <Footer />
    </>
  );
};

export default Partner;
