import React, { useEffect } from "react";
import WhiteArrow from "../assets/Images/WhiteArrow.svg";
import Instagram from "../assets/Images/Instagram.svg";
import Linkedin from "../assets/Images/Linkedin.svg";
import Twitter from "../assets/Images/Twitter.svg";
import WhiteLogo from "../assets/Images/WhiteLogo.svg";
import { useNavigate } from "react-router-dom";
import "./footer.css";

const Footer = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, []);
  return (
    <div className="footer-hushl">
      <div className="footer-top">
        <div className="footer-top-left">
          <img src={WhiteLogo} alt="" />
          <p>
            At Hushl.ai, integrity, innovation, and client success are at our
            core. Discover how our values shape everything we do.
          </p>
          <div className="social">
            <img
              onClick={() => {
                window.open(
                  "https://www.instagram.com/hushl.ai/",
                  "_blank",
                  "noopener noreferrer"
                );
              }}
              src={Instagram}
              alt=""
            />
            <img
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/company/hushl/",
                  "_blank",
                  "noopener noreferrer"
                );
              }}
              src={Linkedin}
              alt=""
            />
            <img
              onClick={() => {
                window.open(
                  "https://twitter.com/hushl_in",
                  "_blank",
                  "noopener noreferrer"
                );
              }}
              src={Twitter}
              alt=""
            />
          </div>
        </div>
        <div className="footer-top-right">
          <p
            onClick={() => {
              navigate("/contact");
            }}
          >
            Get <br /> <span> _Started</span>
          </p>
          <img src={WhiteArrow} alt="" />
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom-item">
          <h1>Resources</h1>
          <p
            onClick={() => {
              navigate("/human-centered-ai");
            }}
          >
            Human-Centric AI
          </p>
          <p
            onClick={() => {
              navigate("/white-papers");
            }}
          >
            White Papers
          </p>
          <p
            onClick={() => {
              navigate("/reports");
            }}
          >
            Reports
          </p>
        </div>
        <div className="footer-bottom-item">
          <h1>Work</h1>
          <p
            onClick={() => {
              navigate("/products/hushl-for-professionals");
            }}
          >
            Hushl for Professionals
          </p>
          <p
            onClick={() => {
              navigate("/products/hushl-for-founders");
            }}
          >
            Hushl for Founders
          </p>
          <p
            onClick={() => {
              navigate("/products/hushl-for-enterprises");
            }}
          >
            Hushl for Enterprises
          </p>
        </div>
        <div className="footer-bottom-item">
          <h1>Industries </h1>
          <p
            onClick={() => {
              navigate("/industries/energy");
            }}
          >
            Energy
          </p>
          <p
            onClick={() => {
              navigate("/industries/investments");
            }}
          >
            Investments
          </p>
          <p
            onClick={() => {
              navigate("/industries/healthcare");
            }}
          >
            Healthcare
          </p>
          <p
            onClick={() => {
              navigate("/industries/heavy-industries");
            }}
          >
            Supply Chain
          </p>
        </div>
        <div className="footer-bottom-item">
          <h1>Solutions </h1>
          <p
            onClick={() => {
              navigate("/solutions/data-engineering");
            }}
          >
            Data Engineering + AI
          </p>
          <p
            onClick={() => {
              navigate("/solutions/process-automation");
            }}
          >
            Process Automation + AI
          </p>
          <p
            onClick={() => {
              navigate("/solutions/product-development");
            }}
          >
            Product Development + AI
          </p>
        </div>

        <div className="footer-bottom-item">
          <h1>Contact</h1>
          <p
            onClick={() => {
              window.open(
                "https://calendly.com/aakarsh-hushl/hushl-introductory-call",
                "_blank",
                "noopener noreferrer"
              );
            }}
          >
            Schedule a meeting
          </p>
          <p
            onClick={() => {
              navigate("/partner");
            }}
          >
            Partner with us
          </p>

          <p
            onClick={() => {
              navigate("/contact");
            }}
          >
            Queries
          </p>
          <p
            onClick={() => {
              navigate("/careers");
            }}
          >
            Careers
          </p>
        </div>
        <div className="footer-bottom-item">
          <h1>Company</h1>
          <p
            onClick={() => {
              navigate("/about");
            }}
          >
            About Us
          </p>
          <p
            onClick={() => {
              navigate("/people");
            }}
          >
            People & Culture
          </p>
          {/* <p
            onClick={() => {
              navigate("/impact");
            }}
          >
            Impact
          </p> */}
        </div>
        <div className="footer-bottom-item">
          <h1>Social</h1>
          <p
            onClick={() => {
              window.open(
                "mailto:aakarsh@hushl.in",
                "_blank",
                "noopener noreferrer"
              );
            }}
          >
            Send us an email
          </p>

          <p
            onClick={() => {
              window.open(
                "https://twitter.com/hushl_in",
                "_blank",
                "noopener noreferrer"
              );
            }}
          >
            Follow us on Twitter
          </p>
        </div>
        <div className="footer-bottom-item">
          <h1>Legal</h1>

          <p>Privacy Policy</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
