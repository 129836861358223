import React, { useRef } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import DownArrowIcon from "../../assets/Images/DownArrowIcon.svg";
import Healthcare1 from "../../assets/Images/Industries/Healthcare1.png";
import Healthcare2 from "../../assets/Images/Industries/Healthcare2.png";
import "./internal.css";
import { useNavigate } from "react-router-dom";

import SolutionsContact from "../../components/Contact/SolutionsContact";
const Energy = () => {
  const navigate = useNavigate();
  const readMore = useRef(null);

  const scrollToReadMore = () => {
    if (readMore.current) {
      readMore.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Navbar />
      <div className="internal-pages-landing">
        <div className="internal-pages-left">
          <h1>
            HCAI is powering the next evolution in <span>_Healthcare</span>
          </h1>

          <div className="buttons">
            <button
              onClick={() => {
                navigate("/white-papers");
              }}
            >
              Download Paper
            </button>
            <button onClick={scrollToReadMore} className="secondary">
              <img src={DownArrowIcon} alt="" />
              Read More
            </button>
          </div>
        </div>
        <div className="internal-pages-right">
          <img src={Healthcare1} alt="" />
        </div>
      </div>
      <div className="internal-second-section" ref={readMore}>
        <img src={Healthcare2} alt="" />
        <div className="internal-second-section-content">
          <h1>Collaboration between HCAI and Healthcare is vital</h1>
          <p>
            Human Centric AI is becoming a cornerstone in healthcare and
            pharmaceuticals, catalyzing collaboration and driving the
            development of patient-centered solutions. This approach is not just
            about integrating AI into healthcare systems, but about leveraging
            AI to enhance patient care, personalize treatment plans, and improve
            health outcomes. It’s about creating AI solutions that understand
            and respond to individual patient needs, and that can adapt to the
            complexities and nuances of human health. Moreover, it’s about
            fostering collaboration between healthcare professionals,
            technologists, and patients, to ensure that AI solutions are
            designed with the patient’s best interests at heart. As we navigate
            this transformative journey, the role of Human Centric AI becomes
            increasingly critical, shaping a healthcare landscape that is
            responsive, adaptive, and ultimately, more effective in improving
            patient outcomes.
          </p>
        </div>
      </div>
      <div className="internal-grad-section">
        <h1>
          “The value of an AI algorithm is not in its academic, scientific
          sophistication. Its value is in what it actually does to help people,
          and what it actually does at the end of the day to change healthcare
          for the better.”
        </h1>
      </div>
      <div className="internal-contact">
        <SolutionsContact />
      </div>
      <Footer />
    </>
  );
};

export default Energy;
