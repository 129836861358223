import React, { useRef } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import DownArrowIcon from "../../assets/Images/DownArrowIcon.svg";
import Investments1 from "../../assets/Images/Industries/Investments1.png";
import Investments2 from "../../assets/Images/Industries/Investments2.png";
import "./internal.css";
import SolutionsContact from "../../components/Contact/SolutionsContact";
import { useNavigate } from "react-router-dom";
const Energy = () => {
  const navigate = useNavigate();
  const readMore = useRef(null);

  const scrollToReadMore = () => {
    if (readMore.current) {
      readMore.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Navbar />
      <div className="internal-pages-landing">
        <div className="internal-pages-left">
          <h1>
            <span>_Investment Strategies </span>are receiving an HCAI makeover
          </h1>

          <div className="buttons">
            <button
              onClick={() => {
                navigate("/white-papers");
              }}
            >
              Download Paper
            </button>
            <button onClick={scrollToReadMore} className="secondary">
              <img src={DownArrowIcon} alt="" />
              Read More
            </button>
          </div>
        </div>
        <div className="internal-pages-right">
          <img src={Investments1} alt="" />
        </div>
      </div>
      <div className="internal-second-section" ref={readMore}>
        <img src={Investments2} alt="" />
        <div className="internal-second-section-content">
          <h1>HCAI is reshaping the face of the finance sector</h1>
          <p>
            The integration of AI in the financial sector is ushering in a new
            era of efficiency, personalization, and risk management. This
            transformation is not just about automating processes, but about
            tailoring services to individual needs, predicting market trends,
            and safeguarding against potential risks. However, the key to
            harnessing the full potential of AI lies in the hands of skilled
            technology teams. These experts are instrumental in steering the
            development and implementation of AI solutions, ensuring they align
            with business goals, comply with regulatory standards, and deliver
            tangible value. As we navigate this digital shift, the role of these
            technology teams becomes increasingly critical, acting as the
            linchpin for successful AI adoption in finance.
          </p>
        </div>
      </div>
      <div className="internal-grad-section">
        <h1>
          “AI decision-making in particular has the potential to raise global
          economic output, and is projected to add a staggering USD 13 trillion
          to the global economy by 2030.” 
        </h1>
      </div>
      <div className="internal-contact">
        <SolutionsContact />
      </div>
      <Footer />
    </>
  );
};

export default Energy;
