import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Close from "../../assets/Images/Close.svg";
import "./thesis.css";
import { data } from "./data";

const Thesis = () => {
  document.body.style.backgroundColor = "#f5f5f7";

  // State to manage modal visibility
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  // Function to open modal and set selected item
  const openModal = (item) => {
    setModalOpen(true);
    setSelectedItem(item);
  };

  // Function to close modal
  const closeModal = () => {
    setModalOpen(false);
    setSelectedItem(null);
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isModalOpen]);
  return (
    <>
      <Navbar />
      <div className="thesis-page">
        <div className="contact-header">
          Our values are drawn from the intersection of design, technology and
          impact
          <p>Read More</p>
        </div>
        {/* <div className="thesis">
          {data.map((item) => {
            return (
              <div key={item.id} className="thesis-item">
                <h3>{item.title}</h3>
                <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
                <button onClick={() => openModal(item)}>Read More</button>
              </div>
            );
          })}
        </div> */}
        <div className="thesis-flex">
          <div className="thesis-flex-left">
            <div className="thesis-item">
              <h3>{data[0].title}</h3>
              <p dangerouslySetInnerHTML={{ __html: data[0].desc }}></p>
              <button
                onClick={() =>
                  openModal({
                    title: data[0].title,
                    fullDesc: data[0].fullDesc,
                    pdfLink: data[0].pdfLink,
                  })
                }
              >
                Read More
              </button>
            </div>
            <div className="thesis-item">
              <h3>{data[2].title}</h3>
              <p dangerouslySetInnerHTML={{ __html: data[2].desc }}></p>
              <button
                onClick={() =>
                  openModal({
                    title: data[2].title,
                    fullDesc: data[2].fullDesc,
                    pdfLink: data[2].pdfLink,
                  })
                }
              >
                Read More
              </button>
            </div>
          </div>
          <div className="thesis-flex-left">
            <div className="thesis-item">
              <h3>{data[1].title}</h3>
              <p dangerouslySetInnerHTML={{ __html: data[1].desc }}></p>
              <button
                onClick={() =>
                  openModal({
                    title: data[1].title,
                    fullDesc: data[1].fullDesc,
                    pdfLink: data[1].pdfLink,
                  })
                }
              >
                Read More
              </button>
            </div>
            <div className="thesis-item">
              <h3>{data[3].title}</h3>
              <p dangerouslySetInnerHTML={{ __html: data[3].desc }}></p>
              <button
                onClick={() =>
                  openModal({
                    title: data[3].title,
                    fullDesc: data[3].fullDesc,
                    pdfLink: data[3].pdfLink,
                  })
                }
              >
                Read More
              </button>
            </div>
          </div>
        </div>
        {isModalOpen && (
          <div className="modal">
            <div className="modal-wrap">
              <img onClick={closeModal} src={Close} alt="" />
              <div className="modal-content">
                <h3>{selectedItem.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: selectedItem.fullDesc,
                  }}
                ></p>

                <a
                  href={selectedItem.pdfLink}
                  target="_blank"
                  rel="noreferrer noopener
                  "
                >
                  Read PDF
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Thesis;
