import React from "react";
import WhiteArrow from "../../assets/Images/WhiteArrow.svg";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import "./resources.css";
import Hcai from "../../assets/Images/Hcai.png";
import Hcai3 from "../../assets/Images/Hcai3.png";
import Hcai4 from "../../assets/Images/Hcai4.png";

const resourcesData = [
  {
    title: "What is human-centered artificial intelligence?",
    src: "https://www.youtube.com/embed/4W2kXBBFDw4?si=uO_XLWb0oUuqx35u",
    description:
      "When we discuss a human approach to AI, what exactly do we mean? Learn about the vision of Stanford HAI.",
    sourceUrl:
      "https://www.youtube.com/watch?v=4W2kXBBFDw4&t=1s&ab_channel=StanfordHAI",
  },
  {
    title: "Human-Centric AI for Computer Vision and Machine Autonomy",
    src: "https://www.youtube.com/embed/WJSXpEkNCfs?si=xnDmfH-8l-AL9VgI",
    description: `Understand and improve human-centric properties such as
      interpretability, steerability, generalizability & fairness`,
    sourceUrl: "https://www.youtube.com/watch?v=WJSXpEkNCfs",
  },
  {
    title: "Ensuring Human Control While Increasing Automation",
    src: "https://www.youtube.com/embed/arOzDH9uALM?si=tyzMQCUkNPrUiIY7",
    description: `
    A new synthesis is emerging that integrates AI technologies with Human-Computer Interaction to produce Human-Centered AI. `,
    sourceUrl: "https://www.youtube.com/watch?v=arOzDH9uALM",
  },
  {
    title:
      "Human-centric AI challenges and opportunities | AI & Policy | Sabrina Kirrane",
    src: "https://www.youtube.com/embed/0mAlZmYi4tQ?si=AFSm5p6WMKtDSsjx",
    description: `
    The Applied Machine Learning Days channel features talks and performances from the Applied Machine Learning Days. `,
    sourceUrl: "https://www.youtube.com/watch?v=0mAlZmYi4tQ",
  },
];

const HCAI = () => {
  return (
    <>
      <Navbar />
      <div className="hcai-landing">
        <img src={Hcai} alt="" />
        <div className="hcai-content">
          <h1>
            Human Centric Artificial <br /> Intelligence
          </h1>
          <p>Ensuring the pod bay doors stay open.</p>
        </div>
      </div>
      <div className="hcai-flex">
        <div className="hcai-flex-item">
          <p>
            In Human-Centric AI, we mix AI with empathy to make tools that
            understand human needs. This blend of tech and humanity leads to
            caring and game-changing solutions. This approach puts us at the
            leading edge of innovation, changing what’s expected in the
            industry. We’re committed to a future where AI is a reliable
            partner, serving people with great accuracy and kindness.
          </p>
          <img src={Hcai3} alt="" />
        </div>
        <div className="hcai-flex-item">
          <img src={Hcai4} alt="" />
          <p>
            As we journey further into this exciting frontier, we continue to
            place humans at the heart of our AI development. We believe in
            creating technology that understands us, learns from us, and grows
            with us. This commitment to human-centric design ensures our AI
            tools are intuitive, accessible, and beneficial to all. It’s not
            just about making smarter machines, but about enhancing human
            potential. We envision a future where AI and humans work in harmony,
            unlocking new levels of creativity, productivity, and innovation.
          </p>
        </div>
      </div>
      <div className="resources">
        <h1>More on HCAI</h1>
        <p>
          AI designed to empower and augment human capabilities, placing people
          at the centre of technological innovation.
        </p>
        <div className="resources-cards">
          {resourcesData.map((resource, index) => (
            <div className="resources-flex" key={index}>
              <div className="resources-flex-left">
                <iframe
                  width="560"
                  height="315"
                  src={resource.src}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="reources-flex-right">
                <h1>{resource.title}</h1>
                <p>{resource.description}</p>
                <button
                  onClick={() => {
                    window.open(
                      resource.sourceUrl,
                      "_blank",
                      "noopener noreferrer"
                    );
                  }}
                >
                  Original Source <img src={WhiteArrow} alt="" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HCAI;
