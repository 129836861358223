import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import Hbet from "../../assets/WhitePapers/hbet.pdf";
import HbetImg from "../../assets/WhitePapers/hbet.png";
import WhiteArrow from "../../assets/Images/WhiteArrow.svg";
import Paper2 from "../../assets/WhitePapers/keynote-shneiderman-human-centered-ai.pdf";
import Paper3 from "../../assets/WhitePapers/National-AI-Engineering-Initiative.pdf";

const WhitePapers = () => {
  const [numPages, setNumPages] = useState(null);
  const [, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  // Default selected PDF

  const pdfs = [
    {
      name: "Human-Centered AI and Machine Learning",
      url: Hbet,
      source: "https://faculty.cc.gatech.edu/~riedl/pubs/hbet.pdf",
      title: "Human-Centered Artificial Intelligence and Machine Learning",
      author: "Mark O.Riedl",
      from: "School of Interactive Computing Georgia Institute of Technology",
    },
    {
      name: `Human-Centered AI:
      Bringing Design Science
      to AI Applications`,
      url: Paper2,
      source:
        "https://www.usf.edu/business/documents/desrist/keynote-shneiderman-human-centered-ai.pdf",
      title: `Human-Centered AI:
      Bringing Design Science
      to AI Applications`,
      author: "Ben Shneiderman",
      from: "University of Maryland",
    },
    {
      name: `National
        AI Engineering Initiative
        HumanCentered AI`,
      url: Paper3,
      source:
        "https://insights.sei.cmu.edu/documents/610/2021_019_001_735364.pdf",
      title: `National
      AI Engineering Initiative
      HumanCentered AI`,
      author: "Hollen Barmer",
      from: " Carnegie Mellon University",
    },
    // Add more PDFs as needed
  ];
  const [selectedPdf, setSelectedPdf] = useState(Hbet);
  const [selectedPdfInfo, setSelectedPdfInfo] = useState(pdfs[0]);

  return (
    <>
      <Navbar />
      <div className="resources reports">
        <h1>White Papers</h1>
        <p>In the business of innovation, the learning never stops.</p>
        <div className="white-papers">
          <div className="white-papers-left">
            {pdfs.map((pdf, index) => (
              <div
                className={`white-papers-left-item ${
                  selectedPdf === pdf.url ? "active" : ""
                }`}
                key={index}
                onClick={() => {
                  setSelectedPdf(pdf.url);
                  setSelectedPdfInfo(pdf);
                }}
              >
                <img src={HbetImg} alt="" />
                <p>{pdf.name}</p>
              </div>
            ))}
          </div>
          <div className="white-papers-right-wrap">
            <div className="white-papers-right">
              <Document
                file={selectedPdf}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    height={
                      window.innerWidth > 1200
                        ? 1100
                        : window.innerWidth > 800
                        ? 900
                        : 800
                    }
                    width={
                      window.innerWidth > 1200
                        ? 1100
                        : window.innerWidth > 800
                        ? 800
                        : 350
                    }
                  />
                ))}
              </Document>
            </div>
            <div className="white-papers-right-text">
              <button
                onClick={() => {
                  window.open(
                    selectedPdfInfo.source,
                    "_blank",
                    "noopener noreferrer"
                  );
                }}
              >
                Orignal Source <img src={WhiteArrow} alt="" />
              </button>
              <p>
                {selectedPdfInfo.title}
                <br />~{selectedPdfInfo.author}
                <br />
                <span> {selectedPdfInfo.from}</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default WhitePapers;
