import React from "react";
import './cards.css'

const NewSectionHorizontalBox=(props)=>{
    return (<>
    <div className="main-card-hori" >
        <div><img className="card1-image-hori" src={props.image} alt="" /></div>
        <div className="card-content1">
            <div className="card-content-top">
                <p className="country-style">{props.country}</p>
                <h3 className="company-style">{props.company}</h3>
            </div>

            <div className="card-content-bottom2">
                <div className="head-styles2">
                    <p>{props.head}</p>
                </div>
                <p className="description-style">{props.description}</p>
            </div>

        </div>
    </div>
    </>)
}
export default NewSectionHorizontalBox


