import React from "react";
import BgGif from "../../assets/Images/Bg.gif";
import "./styles.css";
import Navbar from "../../components/Navbar";
import Arrow from "../../assets/Images/Arrow.svg";
import Team1 from "../../assets/Images/Team1.png";
import Team2 from "../../assets/Images/Team2.png";
import Team3 from "../../assets/Images/Team3.png";
import Linkedin from "../../assets/Images/Linkedin.svg";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import card1 from "../../assets/Images/card1.png";
import card2 from "../../assets/Images/card2.png";
import card3 from "../../assets/Images/card3.png";
import card4 from "../../assets/Images/card4.png";
import card5 from "../../assets/Images/card5.png";
import NewSectionVerticalBox from "../../components/newSectionVerticalBox";
import NewSectionHorizontalBox from "../../components/newSectionHorizontalBox";

import Test2 from "../../assets/Images/test2.jpg";
import Test3 from "../../assets/Images/test3.png";

const Home = () => {
  const navigate = useNavigate();
  document.body.style.backgroundColor = "#fff";
  return (
    <>
      <Navbar />
      <div className="landing-section">
        <div className="bg-landing">
          <img src={BgGif} alt="" />
        </div>
        <div className="landing-text">
          <h1>
            Only Human-Centric AI is <br /> <span> _Sustainable</span>
          </h1>
          <p>
            At Hushl, we're a passionate team of tech innovators <br />{" "}
            dedicated to harnessing AI for positive impact.
          </p>
          {/* <div className="landing-button nabar-cta">
            <button
              className="secondary"
              onClick={() => {
                navigate("about");
              }}
            >
              About Us
            </button>
            <button
              onClick={() => {
                navigate("/contact");
              }}
            >
              Get Started
            </button>
          </div> */}
        </div>
        <div className="home-navogation">
          <div className="home-navogation-inner">
            <div className="home-nav-item">
              <p
                onClick={() => {
                  navigate("/human-centered-ai");
                }}
              >
                Read More about <br />
                <span>_human centric AI</span>
              </p>
              <img src={Arrow} alt="" />
            </div>
            <div className="home-nav-item">
              <p
                onClick={() => {
                  navigate("/careers");
                  navigate("/careers");
                }}
              >
                Explore Working <br />
                <span>_with us</span>
              </p>
              <img src={Arrow} alt="" />
            </div>
            {/* <div className="home-nav-item">
              <p>
                People & <br />
                <span>_culture </span>
              </p>
              <img src={Arrow} alt="" />
            </div> */}
          </div>
        </div>
      </div>
      <div className="second-section">
        <div className="second-section-flex">
          <h2>
            We’re not building the future of AI.
            <p>
              We’re Building a better future <span>with Humans.</span>{" "}
            </p>
          </h2>
          <p>
            Forget the dystopian visions. At hushl.ai, we're a mission-driven
            <span> product accelerator</span> who believe in AI for good. We're
            backed by forward-thinking investors who share our belief: Only
            Human-Centric AI is Sustainable.
          </p>
        </div>
        <div className="second-section-items">
          <div className="second-section-item">
            <h1>People-First AI</h1>
            <p>Solutions that empower humans, not replace them.</p>
          </div>
          <div className="second-section-item">
            <h1>Transparent AI</h1>
            <p>No black boxes. Clear, understandable, and trustworthy</p>
          </div>
          <div className="second-section-item">
            <h1>Ethical AI</h1>
            <p>Built with fairness and responsible practices at the core.</p>
          </div>
          <div className="second-section-item">
            <h1>Sustainable AI</h1>
            <p>Designed for impact & long term continuity</p>
          </div>
        </div>
      </div>

      <div className="new-section">
        {/* <div className="new-section-head">
          
          <h2>
            <span className="black-span">Keeping the </span>{" "}
            <span className="grad-span"> human at center</span>
          </h2>
        </div> */}
        <div className="second-section-flex third-section-head">
          <h2>
            We solve for problems of the future.
            <p>
              Keeping <span>humans at the centre.</span>{" "}
            </p>
          </h2>
        </div>
        <div className="new-section-main">
          <NewSectionVerticalBox
            image={card1}
            country="Singapore "
            company="Spark"
            // head="Authentic . Personalized . Captivating . Intimate            "
            description="In a world consumed by social apps, loneliness continues to be a huge issue. Spark solves for loneliness in mid-to-older adults with a confluence of luxury experiences and artificial intelligence. "
          />
          <div className="card-1">
            <NewSectionVerticalBox
              image={card2}
              country="Global"
              company="Hushl Pilot "
              // head="Innovative. Efficient. Impactful. Inspiring"
              description="Unleashing the power of AI to craft compelling tweets, LinkedIn posts, and blogs, Hushl Pilot is your digital companion for impactful online presence. It’s not just about saving time, it’s about enhancing your voice in the digital world. "
            />
          </div>
          <div className="card-2">
            <NewSectionVerticalBox
              image={card3}
              country="Switzerland "
              company="Wandery"
              // head="Effortless. Reliable. Innovative. Luxurious"
              description="Wandery eases the burden of travel planning with AI-curated, family-friendly stays. It’s a haven of stress-free travel experiences, enriched by personalized AI assistance."
            />
          </div>
          <div className="card-6">
            <NewSectionVerticalBox
              image={card5}
              country="Global"
              company="Hushl Podcast "
              // head="Build Operate Own License"
              description="From concept to distribution, the Hushl Podcast Program empowers thought leaders get their content out into the world."
            />
          </div>
          <div className="card-5">
            <NewSectionHorizontalBox
              image={card5}
              country="Global"
              company="Attis"
              // head="Empowering. Intuitive. Comprehensive. Accessible"
              description="The Attis Bot, is a personal librarian for mental health resources at your fingertips. A seamless interface to a treasure trove of knowledge."
            />
          </div>

          <div className="card-4">
            <NewSectionVerticalBox
              image={card4}
              country="Global"
              company="Hushl Podcast"
              // head="Build. Operate. Own. License"
              description="From concept to distribution, the Hushl Podcast Program helps thought leaders get their content out into the world."
            />
          </div>
        </div>
      </div>
      <div className="clients home-clients">
        <div className="client-card">
          <div className="client-text">
            <h1>
              “When we started this journey, all I had was a strong insight. The
              Research & UX team at Hushl helped convert insights into
              conviction, and their Product team ended up building us a
              phenomenal product in record time.”
            </h1>
            <span>~David Hamlin, Founder & CEO, Wandery Switzerland</span>
          </div>
          <div className="cleint-img david-img">
            <img src={Test2} alt="" />
          </div>
        </div>

        <div className="client-card">
          <div className="client-text">
            <h1>
              "Every member of our team is deeply in sync with team Hushl. The
              process from ideation to launch has been so streamlined that we
              never felt that we were two different teams."
            </h1>
            <span>~ Mario Vista, Chief Product Officer, Spark</span>
          </div>
          <div className="cleint-img">
            <img src={Test3} alt="" />
          </div>
        </div>
      </div>

      <div className="third-section">
        <h2>
          We’re a <span className="black-span">venture</span> backed team of{" "}
          <br /> <span className="grad-span"> impact</span> driven technologists
        </h2>
        <div className="home-team-cards">
          <div className="home-team-card">
            <div className="home-team-card-img">
              <img src={Team3} alt="" />
              <p>
                Adithya
                <br />
                Narayanan
              </p>
            </div>
            <h1>
              Founder,<span> CPO</span>{" "}
            </h1>
            <p>
              An alumnus of Harvard’s Kennedy School, Adithya has an enduring
              passion for design-led impact through education & technology. He
              has previously been a Fellow at Teach For India, business
              architect at IDEO, and investment associate at Draper Kaplan
              Richards. He has worked / lived across India, West Asia, East
              Africa and North America.
            </p>
            <div
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/in/adithya-narayanan-37403377/",
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
              className="social"
            >
              <img src={Linkedin} alt="" />
            </div>
          </div>
          <div className="home-team-card">
            <div className="home-team-card-img">
              <img src={Team2} alt="" />
              <p>
                Aakarsh
                <br /> Yadav
              </p>
            </div>
            <h1>
              Founder, <span> CTO</span>{" "}
            </h1>
            <p>
              After spending two master’s terms at IIT Kharagpur delving deep
              into diverse engineering disciplines, Aakarsh went on to work with
              global big tech firms (Rakuten) and funded startups (5paisa). A
              serial innovator, Aakarsh thrives on building complex tech forward
              solutions. He has lived /worked in India, Russia, Australia, South
              East Asia.
            </p>
            <div
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/in/aakarshyadav/",
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
              className="social"
            >
              <img src={Linkedin} alt="" />
            </div>
          </div>
          <div className="home-team-card">
            <div className="home-team-card-img">
              <img src={Team1} alt="" />
              <p>
                Shariq <br /> A Salar
              </p>
            </div>
            <h1>
              Founder,<span> CEO</span>{" "}
            </h1>
            <p>
              Philomath by nature, Shariq earned his last two masters’ from the
              National Law School of India, besides his earlier degrees in
              Finance, Systems and Engineering. With a body of work across Tech
              Ventures, Private Equity & Healthcare, Shariq has had a string of
              successful leadership stints. He has lived / worked in Europe, the
              Nordics, West Asia and India.
            </p>
            <div
              className="social"
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/in/shariqsalar/",
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              <img src={Linkedin} alt="" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
