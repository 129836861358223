import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import DownArrowIcon from "../../assets/Images/DownArrowIcon.svg";
import "./solutions.css";

import Process1 from "../../assets/Images/solutions/Process1.png";
import Process2 from "../../assets/Images/solutions/Process2.png";
import SolutionsContact from "../../components/Contact/SolutionsContact";

const DataAnalytics = () => {
  return (
    <>
      <Navbar />
      <div className="solutions-page">
        <div className="solutions-header">
          <div className="solutions-header-left">
            <p>Hushl’s Human-Centric AI </p>
            <h1>
              Solution for <br /> <span>_Process Automation + AI</span>
            </h1>
          </div>
          <div className="solutions-header-right">
            <p>
              AI is revolutionizing process automation by enabling systems to
              learn from data, adapt to new inputs, and perform tasks that
              normally require human intelligence.
            </p>
            <div className="buttons">
              <button>Reach Out to Know more</button>
              <button className="secondary">
                <img src={DownArrowIcon} alt="" />
                Read More
              </button>
            </div>
          </div>
        </div>

        <div className="solution-img">
          <img src={Process1} alt="" />
        </div>
      </div>
      <div className="internal-second-section">
        <img src={Process2} alt="" />
        <div className="internal-second-section-content">
          <h1>
            Streamlining Workflows with <span> AI.</span>
          </h1>
          <p>
            AI-powered process automation is not just about streamlining
            operations, it’s about transforming the way businesses function. By
            automating repetitive tasks, it allows teams to focus on strategic,
            high-value activities. Moreover, the intelligent tools learn and
            adapt over time, continuously improving efficiency. This dynamic
            environment fosters innovation, enhances decision-making, and
            ultimately leads to a more competitive business landscape.
          </p>
        </div>
      </div>
      <div className="internal-grad-section">
        <h1>
          “The first rule of any technology used in a business is that
          automation applied to an efficient operation will magnify the
          efficiency.”
        </h1>
      </div>

      <SolutionsContact />
      <Footer />
    </>
  );
};

export default DataAnalytics;
