import React, { useState } from "react";
import Logo from "../assets/Images/Logo.svg";
// import ArrowRight from "../assets/Images/ArrowRight.svg";
import DownArrow from "../assets/Images/DownArrow.svg";
import WhiteArrow from "../assets/Images/WhiteArrow.svg";
import Arrow from "../assets/Images/Arrow.svg";
import "./nav.css";
import Menu from "../assets/Images/Menu.svg";
import { useNavigate } from "react-router-dom";
import MenuBar from "./MenuBar";

const Navbar = () => {
  const navigate = useNavigate();
  const [activeElement, setactiveElement] = useState(0);
  const [showMenu, setshowMenu] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <>
      <div className="navbar-hushl">
        <div
          onClick={() => {
            navigate("/");
          }}
          className="navbar-logo"
        >
          <img src={Logo} alt="" />
        </div>

        <div className="navbar-links">
          <div
            onMouseEnter={() => {
              setactiveElement(1);
              setshowMenu(true);
            }}
            onMouseLeave={() => {
              setactiveElement(0);
              setshowMenu(false);
            }}
            className="navbar-link-item"
          >
            Resources
            <img src={DownArrow} alt="" />
            <div
              className={
                activeElement === 1 && showMenu
                  ? "hover-content show-hover-content"
                  : "hover-content"
              }
              style={{
                width: window.innerWidth,
              }}
            >
              <div className="hover-content-left">
                <h1>Resources</h1>
                <p>
                  Discover valuable insights on HCAI, alongside whitepapers and
                  reports, to empower your understanding and decision-making
                  across diverse sectors.
                </p>
                {/* <div className="cta">
                  Know More <img src={ArrowRight} alt="" />
                </div> */}
              </div>
              <div className="hover-content-right">
                <div className="hover-content-right-items">
                  <div
                    onClick={() => {
                      navigate("/human-centered-ai");
                    }}
                    className="hover-content-right-item"
                  >
                    <h1>Human-Centric AI</h1>
                    <p>
                      AI designed to empower and augment human capabilities,
                      placing people at the center of technological innovation.
                    </p>
                    <div className="cta">
                      know more
                      <img src={Arrow} alt="" />
                    </div>
                    <div className="hover-width"></div>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/white-papers");
                    }}
                    className="hover-content-right-item"
                  >
                    <h1>White Papers</h1>
                    <p>
                      Exploring how human-centric systems can reshape industries
                      and the workforce.
                    </p>
                    <div className="cta">
                      know more
                      <img src={Arrow} alt="" />
                    </div>
                    <div className="hover-width"></div>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/reports");
                    }}
                    className="hover-content-right-item"
                  >
                    <h1>Reports</h1>
                    <p>
                      Comprehensive analysis of the latest technological
                      advancements and industry adoption.
                    </p>
                    <div className="cta">
                      know more
                      <img src={Arrow} alt="" />
                    </div>
                    <div className="hover-width"></div>
                  </div>
                </div>

                <div className="hover-content-right-featued">
                  <h1>
                    “AI adoption is continuing its steady rise: 56 percent of
                    all respondents report adoption in at least one function, up
                    from 50 percent in 2020.”
                  </h1>
                  <div
                    onClick={() => {
                      navigate("/reports");
                    }}
                    className="source"
                  >
                    Read Source
                    <img src={WhiteArrow} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            onMouseEnter={() => {
              setactiveElement(2);
              setshowMenu(true);
            }}
            onMouseLeave={() => {
              setactiveElement(0);
              setshowMenu(false);
            }}
            className="navbar-link-item"
          >
            Work
            <img src={DownArrow} alt="" />
            <div
              className={
                activeElement === 2 && showMenu
                  ? "hover-content show-hover-content"
                  : "hover-content"
              }
              style={{
                width: window.innerWidth,
              }}
            >
              <div className="hover-content-left">
                <h1>Work </h1>
                <p>Empowering Humanity, Unraveling Solutions</p>
                {/* <div className="cta">
                  Know More <img src={ArrowRight} alt="" />
                </div> */}
              </div>
              <div className="hover-content-right">
                <div className="hover-content-right-items">
                  <div className="hover-content-right-item">
                    <h1>
                      {/* Industries we <br /> <span> _work in</span> */}
                      Industries
                    </h1>
                    <div
                      onClick={() => {
                        navigate("/industries/energy");
                      }}
                      className="hover-content-right-item-link"
                    >
                      Energy <img src={Arrow} alt="" />
                    </div>
                    <div
                      onClick={() => {
                        navigate("/industries/investments");
                      }}
                      className="hover-content-right-item-link"
                    >
                      Investments <img src={Arrow} alt="" />
                    </div>
                    <div
                      onClick={() => {
                        navigate("/industries/healthcare");
                      }}
                      className="hover-content-right-item-link"
                    >
                      Healthcare <img src={Arrow} alt="" />
                    </div>
                    <div
                      onClick={() => {
                        navigate("/industries/heavy-industries");
                      }}
                      className="hover-content-right-item-link"
                    >
                      Supply Chain <img src={Arrow} alt="" />
                    </div>
                  </div>
                  <div className="hover-content-right-item">
                    <h1>
                      {" "}
                      {/* Solutions we <br /> <span> _provide</span> */}
                      Solutions
                    </h1>

                    <div
                      onClick={() => {
                        navigate("/solutions/data-engineering");
                      }}
                      className="hover-content-right-item-link"
                    >
                      Data Engineering + AI <img src={Arrow} alt="" />
                    </div>
                    <div
                      onClick={() => {
                        navigate("/solutions/process-automation");
                      }}
                      className="hover-content-right-item-link"
                    >
                      Process Automation + AI <img src={Arrow} alt="" />
                    </div>
                    <div
                      onClick={() => {
                        navigate("/solutions/product-development");
                      }}
                      className="hover-content-right-item-link"
                    >
                      Product Development + AI <img src={Arrow} alt="" />
                    </div>
                  </div>
                  <div className="hover-content-right-item">
                    <h1>
                      {/* Products we <br /> <span> _have created</span> */}
                      Products
                    </h1>
                    <div
                      onClick={() => {
                        navigate("/products/hushl-for-professionals");
                      }}
                      className="hover-content-right-item-link"
                    >
                      Hushl for Professionals
                      <img src={Arrow} alt="" />
                    </div>
                    <div
                      onClick={() => {
                        navigate("/products/hushl-for-founders");
                      }}
                      className="hover-content-right-item-link"
                    >
                      {" "}
                      Hushl for Founders <img src={Arrow} alt="" />
                    </div>
                    <div
                      onClick={() => {
                        navigate("/products/hushl-for-enterprises");
                      }}
                      className="hover-content-right-item-link"
                    >
                      Hushl for Enterprises <img src={Arrow} alt="" />
                    </div>
                  </div>
                </div>

                <div className="hover-content-right-featued">
                  <h1>
                    “AI adoption is continuing its steady rise: 56 percent of
                    all respondents report adoption in at least one function, up
                    from 50 percent in 2020.”
                  </h1>
                  <div
                    onClick={() => {
                      navigate("/reports");
                    }}
                    className="source"
                  >
                    Read Source
                    <img src={WhiteArrow} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="navbar-link-item"
            onMouseEnter={() => {
              setactiveElement(3);
              setshowMenu(true);
            }}
            onMouseLeave={() => {
              setactiveElement(0);
              setshowMenu(false);
            }}
          >
            Company
            <img src={DownArrow} alt="" />
            <div
              className={
                activeElement === 3 && showMenu
                  ? "hover-content show-hover-content"
                  : "hover-content"
              }
              style={{
                width: window.innerWidth,
              }}
            >
              <div
                onClick={() => {
                  navigate("/about");
                }}
                className="hover-content-left"
              >
                <h1>Company</h1>
                <p>
                  {" "}
                  Dive deeper into our mission, vision, and the core values that
                  drive us forward. Discover how we’re making a difference in
                  the world of AI.
                </p>
                {/* <div className="cta">
                  Know More <img src={ArrowRight} alt="" />
                </div> */}
              </div>
              <div className="hover-content-right">
                <div className="hover-content-right-items">
                  <div
                    onClick={() => {
                      navigate("/about");
                    }}
                    className="hover-content-right-item"
                  >
                    <h1>About </h1>
                    <p>
                      Dive deeper into our mission, vision, and the core values
                      that drive us forward. Discover how we’re making a
                      difference in the world of AI.
                      {/* Learn more about why we’re so enthusiastic on Human
                      Centric AI */}
                    </p>
                    <div className="cta">
                      know more
                      <img src={Arrow} alt="" />
                    </div>
                    <div className="hover-width"></div>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/people");
                    }}
                    className="hover-content-right-item"
                  >
                    <h1>People & Culture</h1>
                    <p>
                      More on our diverse community, shared values, and the
                      vibrant tapestry that makes us who we are.
                    </p>
                    <div className="cta">
                      know more
                      <img src={Arrow} alt="" />
                    </div>
                    <div className="hover-width"></div>
                  </div>
                  {/* <div
                    onClick={() => {
                      navigate("/impact");
                    }}
                    className="hover-content-right-item"
                  >
                    <h1>Engagement</h1>
                    <p>
                      Comprehensive analysis of the latest technological
                      advancements and industry adoption.
                    </p>
                    <div className="cta">
                      know more
                      <img src={Arrow} alt="" />
                    </div>
                    <div className="hover-width"></div>
                  </div> */}
                </div>

                <div className="hover-content-right-featued">
                  <h1>
                    “AI adoption is continuing its steady rise: 56 percent of
                    all respondents report adoption in at least one function, up
                    from 50 percent in 2020.”
                  </h1>
                  <div
                    onClick={() => {
                      navigate("/reports");
                    }}
                    className="source"
                  >
                    Read Source
                    <img src={WhiteArrow} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="navbar-link-item"
            onMouseEnter={() => {
              setactiveElement(4);
              setshowMenu(true);
            }}
            onMouseLeave={() => {
              setactiveElement(0);
              setshowMenu(false);
            }}
          >
            Contact
            <img src={DownArrow} alt="" />
            <div
              className={
                activeElement === 4 && showMenu
                  ? "hover-content show-hover-content"
                  : "hover-content"
              }
              style={{
                width: window.innerWidth,
              }}
            >
              <div className="hover-content-left">
                <h1>Contact </h1>
                <p>
                  We’re passionate optimists who thrive on collaborating with
                  individuals from across the globe. If you’re seeking research
                  insights or need advice, do reach out.
                </p>
                {/* <div className="cta">
                  Know More <img src={ArrowRight} alt="" />
                </div> */}
              </div>
              <div className="hover-content-right">
                <div className="hover-content-right-items">
                  <div
                    onClick={() => {
                      navigate("/careers");
                    }}
                    className="hover-content-right-item"
                  >
                    <h1>Careers </h1>
                    <p>
                      We’re passionate optimists who thrive on collaborating
                      with individuals from across the globe. If you’re seeking
                      research insights or need advice, do reach out.
                    </p>
                    <div className="cta">
                      know more
                      <img src={Arrow} alt="" />
                    </div>
                    <div className="hover-width"></div>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/partner");
                    }}
                    className="hover-content-right-item"
                  >
                    <h1>Partner with us</h1>
                    <p>
                      Join forces with us to create innovative solutions and
                      drive meaningful impact.
                    </p>
                    <div className="cta">
                      know more
                      <img src={Arrow} alt="" />
                    </div>
                    <div className="hover-width"></div>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/contact");
                    }}
                    className="hover-content-right-item"
                  >
                    <h1>Reach Out </h1>
                    {/* <p>
                      Comprehensive analysis of the latest technological
                      advancements and industry adoption.
                    </p> */}
                    <div className="cta">
                      know more
                      <img src={Arrow} alt="" />
                    </div>
                    <div className="hover-width"></div>
                  </div>
                </div>

                <div className="hover-content-right-featued">
                  <h1>
                    “AI adoption is continuing its steady rise: 56 percent of
                    all respondents report adoption in at least one function, up
                    from 50 percent in 2020.”
                  </h1>
                  <div
                    onClick={() => {
                      navigate("/reports");
                    }}
                    className="source"
                  >
                    Read Source
                    <img src={WhiteArrow} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="nabar-cta">
          <button
            onClick={() => {
              navigate("/contact");
            }}
          >
            Get Started
          </button>
        </div> */}
        <div
          onClick={() => {
            setOpenMenu(!openMenu);
          }}
          className="menu-navbar"
        >
          <img src={Menu} alt="" />
        </div>
        {openMenu && <MenuBar setOpenMenu={setOpenMenu} />}
      </div>
    </>
  );
};

export default Navbar;
