import React from "react";
import './cards.css'

const NewSectionVerticalBox=(props)=>{
    return (<>
    <div className="main-cards" >
        <div><img className="card1-image" src={props.image} alt="" /></div>
        <div className="card-content">
            <div className="card-content-top">
                <p className="country-style">{props.country}</p>
                <h3 className="company-style">{props.company}</h3>
            </div>

            <div className="card-content-bottom">
                {/* <div className="head-styles">
                    <p>{props.head}</p>
                </div> */}
                <p className="description-style">{props.description}</p>
            </div>

        </div>
    </div>
    </>)
}
export default NewSectionVerticalBox


