import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import DownArrowIcon from "../../assets/Images/DownArrowIcon.svg";
import "./solutions.css";

import Product1 from "../../assets/Images/solutions/Product1.png";
import Product2 from "../../assets/Images/solutions/Product2.png";
import SolutionsContact from "../../components/Contact/SolutionsContact";

const DataAnalytics = () => {
  return (
    <>
      <Navbar />
      <div className="solutions-page">
        <div className="solutions-header">
          <div className="solutions-header-left">
            <p>Hushl’s Human-Centric AI </p>
            <h1>
              Solution for <br /> <span>_Product Development + AI</span>
            </h1>
          </div>
          <div className="solutions-header-right">
            <p>
              AI is subtly transforming product development, infusing it with
              data-driven insights for enhanced decision-making. This quiet
              revolution holds the promise of more efficient, innovative, and
              user-centric products.
            </p>
            <div className="buttons">
              <button>Reach Out to Know more</button>
              <button className="secondary">
                <img src={DownArrowIcon} alt="" />
                Read More
              </button>
            </div>
          </div>
        </div>

        <div className="solution-img">
          <img src={Product1} alt="" />
        </div>
      </div>
      <div className="internal-second-section">
        <img src={Product2} alt="" />
        <div className="internal-second-section-content">
          <h1>
            Creating exceptional products with <span>AI.</span>
          </h1>
          <p>
            Through the integration of AI in our development workflow, we craft
            superior products that surpass anticipated standards. The fusion of
            creativity and intellect ignites revolutionary solutions. This
            synergy propels us to the forefront of innovation, setting new
            benchmarks in the industry. Our commitment to this approach ensures
            a future where technology serves humanity with unprecedented
            efficiency and effectiveness.
          </p>
        </div>
      </div>
      <div className="internal-grad-section">
        <h1>
          “This next generation of AI will reshape every software category and
          every business, including our own. Although this new era promises
          great opportunity, it demands even greater responsibility from
          companies like ours.”
        </h1>
      </div>

      <SolutionsContact />
      <Footer />
    </>
  );
};

export default DataAnalytics;
