import React, { useState } from "react";
import data from "../../pages/Contact/data";
import Arrow from "../../assets/Images/Arrow.svg";

const IndustriesContact = ({ title }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  const [selectedCountry, setSelectedCountry] = useState(
    data.find((country) => country.dial_code === "+1")
  );

  const handleCountryChange = (e) => {
    const selectedCountryCode = e.target.value;
    const selectedCountryData = data.find(
      (country) => country.dial_code === selectedCountryCode
    );
    setSelectedCountry(selectedCountryData);
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const isEmailValid = (email) => {
    // Simple email validation, you can replace it with a more robust validation if needed
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate email
    if (!isEmailValid(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    // Construct the payload
    const payload = {
      firstName,
      lastName,
      email,
      phoneNumber: parseInt(phoneNumber),
      countryCode: selectedCountry.dial_code,
      message,
    };

    try {
      // Make API call to submit the form
      const response = await fetch("https://api-staging.hushl.in/website", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      // Handle the response as needed
      if (response.ok) {
        // Success handling (e.g., show a success message)
        console.log("Form submitted successfully!");
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
        setMessage("");
        setSelectedCountry(data.find((country) => country.dial_code === "+1"));
      } else {
        // Error handling (e.g., show an error message)
        console.error("Error submitting the form");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };
  return (
    <div className="contact-page">
      <div className="contact-page-flex">
        <div className="contact-page-item">
          <div className="contact-header-flex">
            <div className="contact-header">
              To know more,
             
                <span>
                  {" "}
                  please get in touch. <img src={Arrow} alt="" />
                </span>
              
            </div>
          </div>
          <div className="contact-inputs">
            <form onSubmit={handleSubmit}>
              <div className="contact-input-item">
                <input
                  type="text"
                  placeholder="Enter your first name here..."
                  value={firstName}
                  onChange={handleFirstNameChange}
                />
              </div>
              <div className="contact-input-item">
                <input
                  type="text"
                  placeholder="Enter your last name here..."
                  value={lastName}
                  onChange={handleLastNameChange}
                />
              </div>
              <div className="contact-input-item">
                <input
                  type="text"
                  placeholder="Enter your email here..."
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>

              <div className="contact-input-item-flex">
                <div className="contact-input-item">
                  <select
                    value={selectedCountry.dial_code}
                    onChange={handleCountryChange}
                  >
                    {data.map((country) => (
                      <option key={country.code} value={country.dial_code}>
                        {` ${country.flag} ${country.dial_code}`}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="contact-input-item">
                  <input
                    type="tel"
                    placeholder="Enter your phone number here..."
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                  />
                </div>
              </div>
              <div className="contact-input-item">
                <input
                  type="text"
                  placeholder="Enter your message here..."
                  value={message}
                  onChange={handleMessageChange}
                />
              </div>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
        <div className="contact-page-item">
          <div className="contact-page-links-list">
            <div
            onClick={() => {
              window.open(
                "https://calendly.com/aakarsh-hushl/hushl-introductory-call",
                "_blank",
                "noopener noreferrer"
              );
            }}
            className="contact-page-links-list-item">
              <p>
                Schedule a <br /> <span>_Meet</span>
              </p>
              <img src={Arrow} alt="" />
            </div>

            <div
              onClick={() => {
                window.open(
                  "mailto:aakarsh@hushl.in",
                  "_blank",
                  "noopener noreferrer"
                );
              }}
              className="contact-page-links-list-item"
            >
              <p>
                Send us an
                <br /> <span>_Email</span>
              </p>
              <img src={Arrow} alt="" />
            </div>
            <div
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/company/hushl/",
                  "_blank",
                  "noopener noreferrer"
                );
              }}
              className="contact-page-links-list-item"
            >
              <p>
                Connect on <br /> <span>_Linkedin</span>
              </p>
              <img src={Arrow} alt="" />
            </div>
            <div
              className="contact-page-links-list-item"
              onClick={() => {
                window.open(
                  "https://twitter.com/hushl_in",
                  "_blank",
                  "noopener noreferrer"
                );
              }}
            >
              <p>
                Follow us on <br /> <span>_Twitter</span>
              </p>
              <img src={Arrow} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustriesContact;
