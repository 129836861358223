import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import Team1 from "../../assets/Images/Team1.png";
import Arrow from "../../assets/Images/Arrow.svg";

import Team2 from "../../assets/Images/Team2.png";
import Team3 from "../../assets/Images/Team3.png";
import BgGif from "../../assets/Images/Bg.gif";
import Team from "../../assets/Images/Team.png";
import AboutContact from "../../components/Contact/AboutContact";

const Index = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navbar />
      <div className="landing-section about-section">
        <div className="bg-landing about-bg">
          <img src={BgGif} alt="" />
        </div>
        <div className="landing-text about-text">
          <h1>
            We are a team of impact driven <br /> technologists that create{" "}
            <br /> <span> _progress</span>
          </h1>
          <p>
            At Hushl, we're a passionate team of tech innovators dedicated to
            harnessing AI for positive impact.
          </p>
          <div className="landing-button nabar-cta">
            <button
              className="secondary"
              onClick={() => {
                navigate("/partner");
              }}
            >
              Partner with Us
            </button>
            <button
              onClick={() => {
                navigate("/careers");
              }}
            >
              Join Our Team
            </button>
          </div>
        </div>
        <div className="home-navogation about-navigation">
          <div className="home-navogation-inner">
            <div className="home-nav-item">
              <p
                onClick={() => {
                  window.open(
                    "https://www.linkedin.com/company/hushl/",
                    "_blank",
                    "noopener noreferrer"
                  );
                }}
              >
                Connect on <br />
                <span>_linkedin</span>
              </p>
              <img src={Arrow} alt="" />
            </div>
            <div className="home-nav-item">
              <p
                onClick={() => {
                  navigate("/contact");
                }}
              >
                Speak
                <br />
                <span>_to us</span>
              </p>
              <img src={Arrow} alt="" />
            </div>

            <div className="home-nav-item">
              <p
                onClick={() => {
                  navigate("/contact");
                }}
              >
                Send us <br />
                <span>_an idea/feedback</span>
              </p>
              <img src={Arrow} alt="" />
            </div>

            <div className="home-nav-item">
              <p
                onClick={() => {
                  navigate("/contact");
                }}
              >
                Request <br />
                <span>_information</span>
              </p>
              <img src={Arrow} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="team-section">
        <img src={Team} alt="" />
        <p>
          We are a purpose-led collective of technology pioneers and empathy
          specialists, supported by progressive investors, united in our
          conviction that only human-centric AI can drive sustainable progress.
        </p>
      </div>
      <div className="impact-second-section">
          <div className="impact-second-section-top">
            <h1>
              Core <br /> <span> _Fundamentals</span>
            </h1>
            <p>
              At Hushl, we’re more than just an AI product studio. We’re a
              collective driven by a deep-rooted respect for humanity. Our
              conviction is that the real advancement is not in superseding
              humans, but in amplifying their potential. Our ethos is echoed by
              our forward-thinking investors who align with our philosophy: Only
              AI that puts humans at the heart of its design is truly sustainable.
              This human-centric approach forms the bedrock of our core
              fundamentals.
            </p>
          </div>
          <div className="impact-second-section-container">
            <div className="impact-second-section-container-item">
              <span>01</span>
              <h1>Human-Centric AI Development:</h1>
              <p>
                We don't just build features, we build trust. We craft AI that
                understands and respects your users, creating lasting impact.
              </p>
            </div>
            <div className="impact-second-section-container-item">
              <span>02</span>
              <h1>Ethical AI Auditing:</h1>
              <p>
                Ensure your AI operates with transparency, fairness, and alignment
                with your values. We help you mitigate risk and build sustainable
                success.
              </p>
            </div>
            <div className="impact-second-section-container-item">
              <span>03</span>
              <h1>Sustainable AI Implementation:</h1>
              <p>
                We go beyond the technology. We help you minimize the
                environmental impact of your AI solutions throughout their
                lifecycle.
              </p>
            </div>
          </div>
        </div><div className="about-us-team">
            <div className="impact-second-section-top">
              <h1>
                The minds behind <br />
                <span> _Hushl</span>
              </h1>
              <p>
                Behind Hushl is a diverse team of AI experts, engineers,
                researchers, and enthusiasts united by a shared vision. With a
                wealth of experience and expertise, our team is dedicated to
                creating AI solutions that make a difference in the world.
              </p>
            </div>
            <div className="about-team-cards">
              <div className="about-team-card">
                <div className="about-team-card-img">
                  <img src={Team3} alt="" />
                </div>
                <div className="about-team-content">
                  <h1>Adithya Narayanan</h1>
                  <p>_Chief Product Officer</p>
                </div>
              </div>
              <div className="about-team-card">
                <div className="about-team-card-img">
                  <img src={Team2} alt="" />
                </div>
                <div className="about-team-content">
                  <h1>Aakarsh Yadav </h1>
                  <p>_Chief Technology Officer</p>
                </div>
              </div>
              <div className="about-team-card">
                <div className="about-team-card-img">
                  <img src={Team1} alt="" />
                </div>
                <div className="about-team-content">
                  <h1>Shariq Salar </h1>
                  <p>_Chief Executive Officer</p>
                </div>
              </div>
            </div>
          </div><div className="about-container">
            <div className="impact-navigation about-us-navigation">
              <h1>
                Hushl, founded in 2022 with venture backing from London based VC Entrepreneur First,
                has been at the forefront of human-centric AI innovation.
                <span>
                  {" "}
                  Since then, we’ve tackled complex challenges, forged strategic
                  partnerships, and made significant strides
                  <span> towards our mission.</span>
                </span>
              </h1>
              <div className="impact-navigation-items">
                <div
                  onClick={() => {
                    navigate("/products/hushl-for-professionals");
                  } }
                  className="impact-navigation-item"
                >
                  <h2>
                    Experience our <br />
                    <span> human-centric AI solutions in action</span>
                  </h2>
                  <img src={Arrow} alt="" />
                </div>
                {/* <div className="impact-navigation-item">
      <h2>
        Learn more about our{" "}
        <span> commitment to ethical AI principles</span>
      </h2>
      <img src={Arrow} alt="" />
    </div> */}
                <div
                  onClick={() => {
                    navigate("/partner");
                  } }
                  className="impact-navigation-item"
                >
                  <h2>
                    Join our community <br />
                    <span> and collaborate on meaningful AI projects</span>
                  </h2>
                  <img src={Arrow} alt="" />
                </div>
              </div>
            </div>
          </div><AboutContact /><Footer /></>
  );
};

export default Index;
