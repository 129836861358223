import React, { useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import WhiteArrowIcon from "../../assets/Images/WhiteArrowIcon.svg";
import MiniApp from "../../assets/Images/Products/miniapp.png";
import HushlPilot from "../../assets/Images/Products/HushlPilot.png";
import Podcast from "../../assets/Images/Products/podcast.png";
import Wandery from "../../assets/Images/Products/wandery.png";
import Spark from "../../assets/Images/Products/spark.png";
import Data from "../../assets/Images/Products/data.png";
import Healthcare from "../../assets/Images/Products/healthcare.png";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import "./products.css";
const Products = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // Extracting URL path
    const path = location.pathname;

    // Checking if URL contains "hushl-for-professionals" or "hushl-for-founders"
    if (path.includes("/products/hushl-for-professionals")) {
      // Scroll to professionals section
      document.getElementById("hushl-professionals").scrollIntoView();
    } else if (path.includes("/products/hushl-for-founders")) {
      // Scroll to founders section
      document.getElementById("hushl-founders").scrollIntoView();
    } else if (path.includes("/products/hushl-for-enterprises")) {
      // Scroll to enterprise section
      document.getElementById("hushl-enterprise").scrollIntoView();
    }
  }, [location.pathname]);
  return (
    <>
      <Navbar />
      <div className="products-page">
        <div className="products-page-first" id="hushl-professionals">
          <div className="products-page-top">
            <h1>
              Hushl for <br />
              <span>_professionals</span>
            </h1>
            <div className="products-page-top-content">
              <p>
                Hushl’s current repertoire of products was built on one theme -
                to bring a sense of joy and positivity to our users. Through
                meticulous design and thoughtful implementation, Hushl strives
                to deliver products that not only meet but exceed expectations.
              </p>
              <div className="buttons">
                <button
                  onClick={() => {
                    navigate("/contact");
                  }}
                >
                  Reach Out to know More
                </button>
              </div>
            </div>
          </div>
          <div className="products-cards">
            <div className="products-card">
              <div
                className="product-card-img"
                onClick={() => {
                  window.open(
                    "https://miniapp.ai",
                    "_blank",
                    "noopener noreferrer"
                  );
                }}
              >
                <img src={MiniApp} alt="" />
                <div className="product-card-img-text">
                  Hushl miniapp
                  <img src={WhiteArrowIcon} alt="" />
                </div>
              </div>
              <div className="product-card-desc">
                <p>
                  Your GenAI-powered architect for crafting mini lead generator
                  apps. Capture prospects, qualify leads, and build meaningful
                  connections.
                </p>
              </div>
            </div>
            <div className="products-card">
              <div
                onClick={() => {
                  window.open(
                    "https://hushl.in/podcast-cohort",
                    "_blank",
                    "noopener noreferrer"
                  );
                }}
                className="product-card-img"
              >
                <img src={Podcast} alt="" />
                <div className="product-card-img-text">
                  Hushl Podcasts
                  <img src={WhiteArrowIcon} alt="" />
                </div>
              </div>
              <div className="product-card-desc">
                <p>
                From concept to distribution, the Hushl Podcast Program empowers thought leaders get their content out into the world.
                </p>
              </div>
            </div>
            <div className="products-card project-card-large">
              <div
                onClick={() => {
                  window.open(
                    "https://hushl.in",
                    "_blank",
                    "noopener noreferrer"
                  );
                }}
                className="product-card-img"
              >
                <img src={HushlPilot} alt="" />
                <div className="product-card-img-text">
                  Hushl Pilot
                  <img src={WhiteArrowIcon} alt="" />
                </div>
              </div>
              <div className="product-card-desc">
                <p>
                  Unleashing the power of AI to craft compelling tweets,
                  LinkedIn posts, and blogs, Hushl Pilot is your digital
                  companion for impactful online presence. It’s not just about
                  saving time, it’s about enhancing your voice in the digital
                  world.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="products-page-first" id="hushl-founders">
          <div className="products-page-top">
            <h1>
              Hushl for <br />
              <span>_founders</span>
            </h1>

            <div className="products-page-top-content">
              <p>
                Designed for the ambitious, the dreamers, and the doers, the
                Founders Program is an entrepreneur’s gateway to owning
                and operating a tech-enabled business. With the Founders
                Program, you’ll be at the helm of a legally registered,
                tech-enabled enterprise, equipped with a comprehensive suite of
                tools and resources. Plus, you’ll gain privileged access to a
                wealth of proprietary data, learning resources, and expert
                advice. Take the leap and transform your dreams into reality
                with the Founders Program. Your journey to success begins
                now.
              </p>
              <div className="buttons">
                <button
                  onClick={() => {
                    navigate("/contact");
                  }}
                >
                  Speak to Us
                </button>
              </div>
            </div>
          </div>
          <div className="products-cards">
            <div className="products-card project-card-large">
              <div
                onClick={() => {
                  window.open(
                    "https://wandery.ai",
                    "_blank",
                    "noopener noreferrer"
                  );
                }}
                className="product-card-img"
              >
                <img src={Wandery} alt="" />
                <div className="product-card-img-text">
                  Wandery
                  <img src={WhiteArrowIcon} alt="" />
                </div>
              </div>
              <div className="product-card-desc">
                <p>
                  Wandery offers seamless family travel planning with expert
                  AI recommendations for child-friendly stays. Their curated
                  selection of family-friendly hotels ensures stress-free
                  travel, supported by an AI chat interface for personalized
                  assistance throughout the journey.
                </p>
              </div>
            </div>
            <div className="products-card project-card-large">
              <div
                onClick={() => {
                  window.open(
                    "https://spark.love",
                    "_blank",
                    "noopener noreferrer"
                  );
                }}
                className="product-card-img"
              >
                <img src={Spark} alt="" />
                <div className="product-card-img-text">
                  Spark
                  <img src={WhiteArrowIcon} alt="" />
                </div>
              </div>
              <div className="product-card-desc">
                <p>
                  Merging sophisticated experiences with the sophistication of
                  artificial intelligence, Spark provides a solution to the
                  prevalent loneliness among middle-aged and senior adults.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="products-page-first" id="hushl-enterprise">
          <div className="products-page-top">
            <h1>
              Hushl for <br />
              <span>_enterprise</span>
            </h1>
            <div className="products-page-top-content">
              <p>
                Utilizing AI, Hushl provides diverse solutions to enterprises.
                It integrates AI and machine learning for business automation,
                insightful analytics, and increased productivity. Hushl’s
                AI-backed technologies foster new business models, enabling
                enterprises to remain competitive and innovative in the digital
                age.
              </p>
              <div className="buttons">
                <button
                  onClick={() => {
                    navigate("/contact");
                  }}
                >
                  Speak to Us
                </button>
              </div>
            </div>
          </div>
          <div className="products-cards">
            <div className="products-card project-card-large">
              <div className="product-card-img">
                <img src={Data} alt="" />
                <div className="product-card-img-text">
                  Data Engineering
                  {/* <img src={WhiteArrowIcon} alt="" /> */}
                </div>
              </div>
              <div className="product-card-desc">
                <p>
                  Hushl, using AI and machine learning, enhances data engineering, transforming raw data into actionable insights enabling data-driven decisions &
                  strategic planning.
                </p>
              </div>
            </div>
            <div className="products-card project-card-large">
              <div className="product-card-img">
                <img src={Healthcare} alt="" />
                <div className="product-card-img-text">
                  Attis for Healthcare
                  {/* <img src={WhiteArrowIcon} alt="" /> */}
                </div>
              </div>
              <div className="product-card-desc">
                <p>
                  The Attis Bot, is a personal librarian for mental health
                  resources at your fingertips. A seamless interface to a
                  treasure trove of knowledge.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Products;
