import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Arrow from "../../assets/Images/Arrow.svg";
import WhiteArrow from "../../assets/Images/WhiteArrow.svg";
import "./careers.css";
import CareerContact from "../../components/Contact/CareerContact";

const Index = () => {
  const jobList = [
    {
      department: "Engineering",
      jobs: [
        {
          title: "DevOps Engineer",
          description:
            "As a DevOps Engineer at Hushl, you will play a crucial role in maintaining the infrastructure that supports our AI solutions. You will work closely with our development team to streamline our operations and processes, ensuring the smooth deployment of our AI products.",
          fullDesc: `
          
          
          <span>
          Location:</span> Hybrid<br />
          <span>Experience:</span> 3-5 years<br />
          <span> Job Description: </span><br />
          As a DevOps Engineer at Hushl, you will play a crucial role in maintaining the infrastructure that supports our AI solutions. You will work closely with our development team to streamline our operations and processes, ensuring the smooth deployment of our AI products. <br /><br />
          <span>Responsibilities:</span><br />
          Design, implement, and manage the infrastructure used by our AI solutions.<br />
          Automate and streamline our operations and deployment processes.<br />
          Collaborate with the development team to improve system reliability and performance.<br />
          Monitor system logs and activity to identify potential issues.<br />
          Implement security measures to protect our data and infrastructure.<br />
          Provide technical support and guidance to team members.<br /><br />
          <span>Qualifications:</span><br />
          Bachelor’s degree in Computer Science, Information Technology, or a related field.<br />
          Proven experience as a DevOps Engineer or similar role in software development and system administration.<br />
          Experience with databases, networks (LAN, WAN), and patch management.<br />
          Knowledge of scripting languages such as Java, Python, Ruby, Bash.<br />
          Experience with open-source technologies and cloud services (such as AWS, Google Cloud, or Azure).<br />
          Strong problem-solving skills and attention to detail.<br />
          Excellent communication and teamwork skills.<br /><br />
          <span>Preferred Qualifications:</span><br />
          Experience in an AI-focused environment.<br />
          Knowledge of machine learning model deployment.<br />
          Familiarity with containerization technologies like Docker and Kubernetes.<br />
          Experience with CI/CD pipelines.<br /><br />
          As a DevOps Engineer at Hushl, you will have the opportunity to work in a dynamic and innovative environment, where your skills will be valued and your creativity will be encouraged. We look forward to your application!`,
          requirements: [
            "Bachelor's degree in Computer Science",
            "Experience with JavaScript, Python, etc.",
          ],
        },
        {
          title: "Software  Engineer",
          description:
            "We’re in search of a software developer who finds motivation in overcoming obstacles, flourishes amidst innovation, and carries the ethos of a startup. Ideally, this individual would resonate with our commitment to exceed expectations and play a part in our endeavor to enhance user experiences with our innovative solutions.",
          fullDesc: `
          <span>
            Location:</span> Hybrid<br />
            <span>Experience:</span> 3-5 years<br />
            <span> Job Description: </span><br />
            We’re on the lookout for a Software Developer who is not only invigorated by challenges but also thrives in an innovative environment and carries the ethos of a startup. This role is ideal for someone who shares our dedication to surpassing expectations and has a keen interest in enhancing user experiences with our innovative solutions.<br /><br />
            <span>Responsibilities:</span><br />
            Design, code, test and manage various applications<br />
            Collaborate with the engineering team and product team to establish the best products<br />
            Be accountable for high-quality code that is scalable and reliable<br />
            Work on bug fixes and improving application performance<br /><br />
            <span>Requirements:</span><br />
            Bachelor’s degree in Computer Science or a related field<br />
            3-5 years of experience in software development<br />
            Strong knowledge of data structures, system design, and algorithms<br />
            Experience with open-source projects<br />
            Self-driven and a startup mindset<br />
            Excellent problem-solving skills<br />
            Good interpersonal and communication skills<br /><br />
            <span>Nice to Have:</span><br />
            Experience with Agile methodologies<br />
            Knowledge of a variety of programming languages<br /><br />
            At our company, we value diversity and always treat all employees and job applicants based on merit, qualifications, competence, and talent. We do not discriminate on the basis of race, religion, color, national origin, gender, sexual orientation, age, marital status, veteran status, or disability status.`,
          requirements: [
            "Experience with AWS, Docker, Kubernetes",
            "Scripting skills in Bash, Python, etc.",
          ],
        },
      ],
      type: "normal",
    },
    {
      department: "Product",
      jobs: null,
      type: "normal",
    },
    {
      department: "Marketing",
      jobs: null,
      type: "normal",
    },
    {
      department: "Finance",
      jobs: null,
      type: "normal",
    },
    {
      department: "Sales",
      jobs: null,
      type: "normal",
    },
    {
      department: "Open Application",
      jobs: null,
      type: "open",
    },
  ];

  const departments = jobList.map((item) => item.department);
  const defaultDepartment = departments[0]; // First department as default

  const [selectedDepartment, setSelectedDepartment] =
    useState(defaultDepartment);
  const [selectedJob, setSelectedJob] = useState(null);

  const handleDepartmentClick = (department) => {
    setSelectedDepartment(department);
    setSelectedJob(null); // Reset selected job when a new department is clicked
  };

  const handleJobClick = (job) => {
    setSelectedJob(job);
  };

  return (
    <>
      <Navbar />
      <div className="careers-page">
        <div className="careers-page-header">
          <h1>
            Be a part of our _Team <br />
            <span>
              Find your dream Job at Hushl <img src={WhiteArrow} alt="" />
            </span>
          </h1>
          <div className="careers-links">
            <div className="contact-page-links-list">
              <div
                onClick={() => {
                  window.open(
                    "mailto:aakarsh@hushl.in",
                    "_blank",
                    "noopener noreferrer"
                  );
                }}
                className="contact-page-links-list-item"
              >
                <p>
                  Send us an
                  <br /> <span>_Email</span>
                </p>
                <img src={WhiteArrow} alt="" />
              </div>
              <div
                onClick={() => {
                  window.open(
                    "https://www.linkedin.com/company/hushl/",
                    "_blank",
                    "noopener noreferrer"
                  );
                }}
                className="contact-page-links-list-item"
              >
                <p>
                  Connect on <br /> <span>_Linkedin</span>
                </p>
                <img src={WhiteArrow} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="career-page-container">
          <h1>Open roles</h1>
          <div className="career-page-container-flex">
            <div className="sidebar">
              {departments?.map((department, index) => {
                return (
                  <div
                    className={`sidebar-item ${
                      selectedDepartment === department ? "active" : ""
                    }`}
                    key={index}
                    onClick={() => handleDepartmentClick(department)}
                  >
                    <p>{department}</p>
                  </div>
                );
              })}
            </div>
            <div className="job-container">
              {selectedJob ? (
                <div className="job-desc-cont">
                  <h2>{selectedJob} </h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: jobList
                        .find((item) => item.department === selectedDepartment)
                        .jobs.find((item) => item.title === selectedJob)
                        .fullDesc,
                    }}
                  ></p>
                  <CareerContact />
                </div>
              ) : (
                <div className="job-list">
                  {jobList.find(
                    (item) => item.department === selectedDepartment
                  ).jobs ? (
                    jobList
                      .find((item) => item.department === selectedDepartment)
                      .jobs.map((job, index) => (
                        <div
                          key={index}
                          className="job-item"
                          onClick={() => handleJobClick(job.title)}
                        >
                          <div className="job-item-content">
                            <h2>{job.title}</h2>
                            <p>
                              {job.description.substring(0, 160)}... <br />
                            </p>
                          </div>
                          <div className="contact-page-links-list-item">
                            <p>
                              Apply for <br />
                              <span>_this role</span>
                            </p>
                            <img src={Arrow} alt="" />
                          </div>
                        </div>
                      ))
                  ) : (
                    <div>No jobs available</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Index;
